

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

@Component({})
export default class Mywemloaccount extends Vue {
  public manageProcessorButton = true;
  // public manageProcessor() {
  //   this.$router.push({
  //     path: this.$getCurrentUserTypePath("manage-processor")
  //   });
  // }

  public manageAccount() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("my-account")
    });
  }

  public manageLenders() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("lender-relations")
    });
  }
  public manageVendors() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("manage-vendors")
    });
  }

  public manageUsers() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("manage-users")
    });
  }
  public manageCustomerRetention() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("customer-retention")
    });
  }

  get disableRoles() {
    if (this.$store.state.sessionObject.type != "Broker") {
      let manageUser = this.$store.state.sessionObject.access[0];
      if (manageUser) {
        if (
          manageUser.component == "manageUsers" &&
          manageUser.canAccess == true
        ) {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  // public async manageProcessorAccess() {
  //   try {
  //     let response = await Axios.post(
  //       BASE_API_URL + "broker/userDetails",
  //       {
  //         userId: this.$store.state.sessionObject.userId,
  //         userType: this.$store.state.sessionObject.type
  //       },
  //       {
  //
  //       }
  //     );
  //     this.manageProcessorButton = response.data.manageProcessorAccess;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  mounted() {
    if (this.$store.state.sessionObject.type != "Broker") {
      // this.manageProcessorAccess();
    }
  }
}
